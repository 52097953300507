var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "70%", disabled: _vm.disabled },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "div",
                  _vm._g(_vm._b({}, "div", attrs, false), on),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.versionDetailsDialog,
        callback: function ($$v) {
          _vm.versionDetailsDialog = $$v
        },
        expression: "versionDetailsDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(_vm._s(_vm.name))]),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "hidden-sm-and-down",
                          attrs: { cols: "2" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("v-img", {
                                staticClass: "mx-3",
                                attrs: { src: _vm.iconUrl, contain: "" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-5" },
                            [
                              _vm._l(_vm.selectedTags, function (tag, index) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: `selected-tag-${index}`,
                                    staticClass: "mr-2 my-1",
                                    attrs: { small: "", color: "primary" },
                                  },
                                  [_vm._v(" " + _vm._s(tag) + " ")]
                                )
                              }),
                              _vm._l(_vm.tagList, function (tag, index) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: `tag-${index}`,
                                    staticClass: "mr-2 my-1",
                                    attrs: { small: "" },
                                  },
                                  [_vm._v(_vm._s(tag))]
                                )
                              }),
                            ],
                            2
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "overflow-y-auto",
                          staticStyle: { "max-height": "70vh" },
                          attrs: { cols: "12", sm: "10" },
                        },
                        [
                          _c("viewer", {
                            ref: "appVersionDescription",
                            attrs: {
                              id: "appVersionDescription",
                              initialValue: "Fetching version details",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.versionDetailsDialog = false
                    },
                  },
                },
                [_vm._v("close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }